import React from 'react';

class Quiz extends React.Component{
  constructor (props){
      super(props);
      this.state = {
          keyToShow: 0,
          typeMap: {
            'b-to-j': 0,
            'j-to-b': 1
          },
          opositeTypeMap: {
            'b-to-j': 1,
            'j-to-b': 0
          },
          showAnswer: 'none',
          answer: '',
          quiz: this.props.state.quiz,
          answerLabel: 'Грешен отговор!'
      }
  }

  changeQuestion (type){
    var number = this.state.keyToShow;
    if (type == 'prev'){
        number = number -1;
    }else{
        number = number +1;
    }

    if(number >=0 && typeof this.props.state.quiz[number] != 'undefined'){
        this.setState({ keyToShow: number});
        this.setState({ showAnswer: 'none'});
        this.setState({ answer: ''});
        this.setState({ answerLabel: 'Грешен отговор!'});
    }
  }

  render (){
      const question = this.props.state.quiz[this.state.keyToShow];
      const word = question[this.state.typeMap[this.props.state.testType]];

      var answer;
      if (this.props.state.testType == 'j-to-b'){
         answer = question[this.state.typeMap['b-to-j']];
      }else{
         answer = question[this.state.typeMap['j-to-b']];
      }

      return (
        <React.Fragment>
            <h4>Въпрос {this.state.keyToShow +1}</h4>
            <h1 style={{fontSize: '40px'}}>{word}</h1>
            <h2 style={{display: this.state.showAnswer}}>{answer} ({question[2]})</h2>

            <h4 style={{
                marginTop: '40px',
                display: (this.state.showAnswer == 'block') ? 'block': 'none',
                color: (this.state.quiz[this.state.keyToShow].isTrue) ? 'green' : 'red'
            }}>{this.state.answerLabel}</h4>

            <div className="form-group" style={{marginTop: '40px'}}>
                <input 
                    style={{
                        display: (this.state.showAnswer == 'block') ? 'none': 'block'
                    }}
                    name="answer" type="text" className="form-control" value={this.state.answer}
                    placeholder={this.props.state.headerMap[this.state.opositeTypeMap[this.props.state.testType]]} 
                    onChange={(e) => {
                        this.setState({ answer: e.target.value});
                        var quiz = this.state.quiz;
                        quiz[this.state.keyToShow].answer = e.target.value;
                        this.setState({ quiz: quiz});
                    }}
                />
            </div>
            <button 
                className="btn btn-light" 
                style={{
                    display: (this.state.showAnswer == 'block') ? 'none': 'block'
                }}
                onClick={() => {
                    if(this.state.quiz[this.state.keyToShow].answer != ''){
                        this.setState({ showAnswer: 'block'});
                    
                        if(answer == this.state.quiz[this.state.keyToShow].answer){
                            this.setState({ answerLabel: 'Браво! Верен отговор!'});
                            
                            var quiz = this.state.quiz;
                            quiz[this.state.keyToShow].isTrue = true;
                            this.setState({ quiz: quiz});
                        }
                    }
                    
                }}
            >Отговори</button>
            <div className="row" style={{width: '100%',display: 'block',position: 'absolute', bottom: '0px', padding: '20px'}}>
                <button 
                    style={{display: (this.state.keyToShow == 0) ? 'none': 'block'}} 
                    className="btn btn-light float-left" 
                    onClick={() => this.changeQuestion('prev')}
                >Предишна дума</button>
                <button 
                    style={{display: (this.state.keyToShow +1 == this.props.state.questionsCount) ? 'none': 'block'}}  
                    className="btn btn-light float-right" 
                    onClick={() => this.changeQuestion('next')}
                >Следваща дума</button>
                <button 
                    style={{display: (this.state.keyToShow +1 == this.props.state.questionsCount) ? 'block': 'none'}}  
                    className="btn btn-primary float-right" 
                    onClick={() => {
                        var results = {
                            isTrue: 0,
                            isFalse: 0,
                        };

                        this.state.quiz.forEach(function(entry) {
                            if (entry.isTrue){
                                results.isTrue =  results.isTrue + 1
                            }else{
                                results.isFalse =  results.isFalse + 1
                            }
                        });

                        this.props.state.changeMainState("results");
                        this.props.state.setResults(results);
                    }}
                >Покажи резултатите</button>
            </div>
        </React.Fragment>
      );
  }
}
export default Quiz;

import React from 'react';
import { Route, Link, BrowserRouter as Router, Switch, Redirect } from 'react-router-dom';
import Quiz from './Quiz';
import XLSX from 'xlsx'
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';

class App extends React.Component{
  constructor (props){
    super(props);

    this.state = {
      questionsCount: 10,
      testType: 'b-to-j',
      state: 'configuration',
      words: {},
      headerMap: {},
      quiz: {},
      results: {},
      changeMainState: (newState) => {
        this.setState({state: newState})
      },
      setResults: (results) => {
        this.setState({results: results})
      }
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount(){
    var file = process.env.PUBLIC_URL + './words.xlsx';

    fetch(file)
    .then(response => response.blob())
    .then(blob => {   
      const reader = new FileReader();
      reader.onload = (e) => {
        /* Parse data */
        const bstr = e.target.result;
        const wb = XLSX.read(bstr, {type: 'binary'});
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_json(ws, {header:1});
        /* Update state */

        this.setState({ headerMap: data[0] });
        delete data[0];
        this.setState({ words: data});
      };
      reader.readAsBinaryString(blob);        
    });
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();

    //Make quiz
    var arr = this.state.words;
    var quiz = [];

    for (let i = 0; i < this.state.questionsCount; i++) { 
      var key = Math.floor(Math.random() * arr.length);
      var question = arr[key];
      Object.assign(question, {answer: "",isTrue: false});
      quiz.push(question);   
    }

    this.setState({ state: 'quiz'});
    this.setState({ quiz: quiz});
  }

  render () {
    switch (this.state.state){
      case "quiz":
        return (
          <div className="container">
            <header className="App-header">
              <Quiz state={this.state} />
            </header>
          </div>
        );
      break;
      case "results":
          return (
            <div className="container">
              <header className="App-header">
                <h3>Брой верни отговори: {this.state.results.isTrue}</h3>
                <h3>Брой грешни отговори: {this.state.results.isFalse}</h3>
                <br />
                <button 
                    className="btn btn-primary" 
                    onClick={() => {
                        this.state.changeMainState("")
                    }}
                >Нов тест</button>
              </header>
            </div>
          )
      break
      default:
        return (
          <div className="container">
            <header className="App-header">
              <h2>Тест по японски език</h2>
              <br />
              <form onSubmit={this.handleSubmit}>
                <div className="form-group">
                  <label htmlFor="questionsCount">Избери брой въпроси за теста</label>
                  <input name="questionsCount" onChange={this.handleChange} defaultValue="10" type="number" className="form-control" id="questionsCount" placeholder="Брой" />
                </div>
                <div className="form-group">
                  <label htmlFor="testType">Избери тип на теста</label>
                  <select defaultValue="b-to-j" onChange={this.handleChange} name="testType" className="form-control" id="testType">
                    <option value="b-to-j" >От български на японски</option>
                    <option value="j-to-b">От японски на български</option>
                  </select>
                </div>
                <button type="submit" style={{width: '100%'}} className="btn btn-primary">Започни</button>
              </form>
            </header>
          </div>
        );
    }
  }
}

export default App;
